import * as React from "react";
import type { HeadFC } from "gatsby";

import Navigation from "../components/Navigation";
import { Footer } from "../components/Footer";

import { StaticImage } from "gatsby-plugin-image";

export const Head: HeadFC = () => (
    <>
        <title>Our Freedom, Our Future</title>
    </>
);

const IndexPage = () => {
    return (
        <div className="font-['Source_Serif_4'] bg-black text-white">
            <Navigation />

            <div className="pt-12 w-full h-screen min-h-[430px] relative grid">
                <StaticImage
                    src="../images/big.webp"
                    alt=""
                    style={{ position: "absolute", objectFit: "cover", width: "100%", height: "100%" }}
                />

                <div className="relative text-white self-end text-center sm:text-right sm:pr-6">
                    <h2 className="text-3xl mb-6 lg:text-4xl lg:mb-8 font-semibold">OUR FREEDOM</h2>
                    <h2 className="text-3xl mb-6 lg:text-4xl lg:mb-8 font-semibold">OUR FUTURE</h2>
                    <h2 className="text-xl mb-8 lg:text-2xl lg:mb-10 font-semibold">Stand with Tulsi &trade;</h2>
                    <div className="text-xl mb-8 lg:mb-8 lg:text-2xl">
                        <a
                            className="rounded-md p-3 bg-red-500"
                            href="https://secure.anedot.com/ourfreedomourfuture/donate"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Donate Now
                        </a>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default IndexPage;
